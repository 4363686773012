<template>
  <el-menu
    class="el-menu-demo"
    mode="horizontal"
    :ellipsis="false"
    background-color="#00251A"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <el-menu-item index="0">
      <!-- <img src="@/assets/images/golden_logo.png" style="max-height: 68px" /> -->
      <img src="@/assets/images/logo_white.png" style="max-height: 50px" />
    </el-menu-item>
    <div class="flex-grow" />
    <el-sub-menu index="1">
      <template #title>{{ authUser.first_name }}</template>
      <el-menu-item index="2-1">Profile</el-menu-item>
      <el-menu-item index="2-2" @click="logoutMeOut()">Logout</el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HeaderComp",
  computed: {
    ...mapGetters("auth", ["authUser"]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),

    logoutMeOut() {
      this.logout().then(() => {
        this.$notify({
          title: "Success",
          message: "Logout Successful",
          type: "success",
        });
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style>
.flex-grow {
  flex-grow: 1;
}
</style>
